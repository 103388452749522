<script>
    import {_, locale} from 'svelte-i18n';
    import Item from './Item.svelte';
    import {gameConfig} from "../../stores.js";

    export let item;
    export let gameItem;

    export let itemDetails = {};

    function setItemDetails() {
        itemDetails.title = hasNumber(item.sku) ? $_('UpgradeScroll' + extractNumberFromString(item.sku)) : $_('UpgradeScroll');
        itemDetails.description = hasNumber(item.sku) ? $_('UpgradeScrolls' + extractNumberFromString(item.sku) + 'ToolTip') : $_('UpgradeScrollsToolTip');
        itemDetails.amount = item.amount;
        itemDetails.icon = hasNumber(item.sku) ? 'upgrade_scrolls_' + extractNumberFromString(item.sku) : 'upgrade_scrolls';
        itemDetails.rarity = gameItem.rarity;
        itemDetails.expireAt = $gameConfig.temporaryItems[item.id];
        itemDetails.claimed = item.claimed;
    }

    function extractNumberFromString(str) {
        const match = str.match(/\d+/);
        return match ? parseInt(match[0], 10) : null;
    }

    function hasNumber(str) {
        return /\d/.test(str);
    }

    locale.subscribe(setItemDetails);
</script>

<Item {itemDetails}/>

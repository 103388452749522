<script>
    import {gameConfig} from '../stores';

    import ItemSummonMapFort from "./Items/ItemSummonMapFort.svelte";
    import ItemStamina from "./Items/ItemStamina.svelte";
    import ItemToken from "./Items/ItemToken.svelte";
    import ItemResetHeroSkills from "./Items/ItemResetHeroSkills.svelte";
    import ItemResetMarchPreset from "./Items/ItemResetMarchPreset.svelte";
    import ItemInstantLiberationPassPack from "./Items/ItemInstantLiberationPassPack.svelte";
    import ItemMarchRecall from "./Items/ItemMarchRecall.svelte";
    import ItemTroop from "./Items/ItemTroop.svelte";
    import ItemSupplyPart from "./Items/ItemSupplyPart.svelte";
    import ItemEquipmentPart from "./Items/ItemEquipmentPart.svelte";
    import ItemUpgradeScroll from "./Items/ItemUpgradeScroll.svelte";
    import ItemBookOfWar from "./Items/ItemBookOfWar.svelte";
    import ItemMarchSpeedUp from "./Items/ItemMarchSpeedUp.svelte";
    import ItemLootCrateKey from "./Items/ItemLootCrateKey.svelte";
    import ItemVipItem from "./Items/ItemVipItem.svelte";
    import ItemLordXp from "./Items/ItemLordXp.svelte";
    import ItemCommanderTalentParts from "./Items/ItemCommanderTalentParts.svelte";
    import ItemRefreshQuests from "./Items/ItemRefreshQuests.svelte";
    import ItemCommanderRankPoints from "./Items/ItemCommanderRankPoints.svelte";
    import ItemBooster from "./Items/ItemBooster.svelte";
    import ItemTeleport from "./Items/ItemTeleport.svelte";
    import ItemSpeedUp from "./Items/ItemSpeedUp.svelte";
    import ItemResource from "./Items/ItemResource.svelte";
    import ItemGold from './Items/ItemGold.svelte';
    import ItemAllianceGift from './Items/ItemAllianceGift.svelte';
    import ItemCommanderUniversalTalentPart from './Items/ItemCommanderUniversalTalentPart.svelte';
    import ItemUpgradeCastle from "./Items/ItemUpgradeCastle.svelte";
    import ItemInstantResearch from "./Items/ItemInstantResearch.svelte";
    import ItemLootChest from "./Items/ItemLootChest.svelte";
    import ItemLiberationPassPack from "./Items/ItemLiberationPassPack.svelte";
    import '../styles/vars.scss';
    import ItemChangeName from "./Items/ItemChangeName.svelte";
    import ItemGoldenScroll from "./Items/ItemGoldenScroll.svelte";
    import ItemResetCommanderSkills from "./Items/ItemResetCommanderSkills.svelte";
    import ItemPackGameToken from "./Items/ItemPackGameToken.svelte";
    import ItemVanityAvatar from "./Items/ItemVanityAvatar.svelte";
    import ItemVanityFrame from "./Items/ItemVanityFrame.svelte";
    import ItemVanityChat from "./Items/ItemVanityChat.svelte";
    import ItemCastleSkin from "./Items/ItemCastleSkin.svelte";
    import ItemResearchScroll from "./Items/ItemResearchScroll.svelte";

    export let storePack;
    export let goldAmount = 0;
    export let globals = {};
    export let items = [];
    export let hasMask = true;
    export let areRewards = true;
</script>
{#if $gameConfig}
    {#await $gameConfig.gameItems then gameItems}
        <div class={areRewards ? "items" : "rewards"}>
            <div class="{hasMask ? 'mask': ''}">
                {#if goldAmount > 0}
                    <ItemGold item=null {goldAmount} itemsAmount={globals.goldItemAmount || 1} bonus={false}/>
                {/if}
                {#if storePack.extraGold}
                    <ItemGold item=null {goldAmount} itemsAmount={globals.goldItemAmount || 1} bonus={true}/>
                {/if}
                {#if items.length > 0}
                    {#each items as item}
                        {#if gameItems[item.id].category === 'resources' || gameItems[item.id].category === 'suppliesChest' || gameItems[item.id].category === 'equipmentChest' || gameItems[item.id].category === 'chest'}
                            <ItemResource
                                    {item}
                                    gameItem={gameItems[item.id]}
                                    {globals}
                            />
                        {:else if gameItems[item.id].category === 'gold'}
                            <ItemGold {item} {goldAmount} itemsAmount={item.amount} bonus={false}/>
                        {:else if gameItems[item.id].category === 'speedUp'}
                            <ItemSpeedUp
                                    {item}
                                    gameItem={gameItems[item.id]}
                                    {globals}
                            />
                        {:else if gameItems[item.id].category === 'teleport'}
                            <ItemTeleport {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'researchSpeedBooster' || gameItems[item.id].category === 'gatheringSpeedBooster' || gameItems[item.id].category === 'troopHealingSpeedBooster' || gameItems[item.id].category === 'troopLoadBooster' || gameItems[item.id].category === 'ironProductionBooster' || gameItems[item.id].category === 'foodProductionBooster' || gameItems[item.id].category === 'stoneProductionBooster' || gameItems[item.id].category === 'woodProductionBooster' || gameItems[item.id].category === 'silverProductionBooster' || gameItems[item.id].category === 'upkeepReductionBooster' || gameItems[item.id].category === 'trainingSpeedBooster' || gameItems[item.id].category === 'lordXpBooster' || gameItems[item.id].category === 'constructionSpeedBooster' || gameItems[item.id].category === 'marchSpeedBooster' || gameItems[item.id].category === 'antiscoutBooster' || gameItems[item.id].category === 'marchSizeBooster' || gameItems[item.id].category === 'troopAttackBooster' || gameItems[item.id].category === 'troopAttackDebuffBooster' || gameItems[item.id].category === 'troopDefenseBooster' || gameItems[item.id].category === 'troopDefenseDebuffBooster' || gameItems[item.id].category === 'troopHealthBooster' || gameItems[item.id].category === 'troopHealthDebuffBooster' || gameItems[item.id].category === 'monsterMarchSpeedBooster' || gameItems[item.id].category === 'peaceShield'}
                            <ItemBooster
                                    {item}
                                    gameItem={gameItems[item.id]}
                                    {globals}
                            />
                        {:else if gameItems[item.id].category === 'rankPoints'}
                            <ItemCommanderRankPoints
                                    {item}
                                    gameItem={gameItems[item.id]}
                            />
                        {:else if gameItems[item.id].category === 'refreshMissions'}
                            <ItemRefreshQuests {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'commanderTalentParts'}
                            <ItemCommanderTalentParts
                                    {item}
                                    gameItem={gameItems[item.id]}
                            />
                        {:else if gameItems[item.id].category === 'lordXp'}
                            <ItemLordXp {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'vipPoints' || gameItems[item.id].category === 'vipTime'}
                            <ItemVipItem
                                    {item}
                                    gameItem={gameItems[item.id]}
                                    {globals}
                            />
                        {:else if gameItems[item.id].category === 'lootCrateKey'}
                            <ItemLootCrateKey {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'marchSpeedUp'}
                            <ItemMarchSpeedUp {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'bookOfWar'}
                            <ItemBookOfWar {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'upgradeScroll'}
                            <ItemUpgradeScroll {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'equipmentPart'}
                            <ItemEquipmentPart {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'supplyPart'}
                            <ItemSupplyPart {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'troopsItem'}
                            <ItemTroop {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'marchRecall'}
                            <ItemMarchRecall {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'instantLiberationPassPack'}
                            <ItemInstantLiberationPassPack
                                    {item}
                                    gameItem={gameItems[item.id]}
                            />
                        {:else if gameItems[item.id].category === 'resetMarchPreset'}
                            <ItemResetMarchPreset
                                    {item}
                                    gameItem={gameItems[item.id]}
                            />
                        {:else if gameItems[item.id].category === 'resetHeroSkills'}
                            <ItemResetHeroSkills {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'token'}
                            <ItemToken {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'heroStamina'}
                            <ItemStamina {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'fortMap'}
                            <ItemSummonMapFort {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'commanderUniversalTalentParts'}
                            <ItemCommanderUniversalTalentPart {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'instantUpgrade'}
                            <ItemUpgradeCastle {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'instantResearch'}
                            <ItemInstantResearch {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'lootChest'}
                            <ItemLootChest {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'liberationPassPack'}
                            <ItemLiberationPassPack {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'changeName'}
                            <ItemChangeName {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'goldenScroll'}
                            <ItemGoldenScroll {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'resetCommanderSkills'}
                            <ItemResetCommanderSkills {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'packGameToken'}
                            <ItemPackGameToken {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'vanityAvatar'}
                            <ItemVanityAvatar {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'vanityFrame'}
                            <ItemVanityFrame {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'castleSkin'}
                            <ItemCastleSkin {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'vanityChat'}
                            <ItemVanityChat {item} gameItem={gameItems[item.id]}/>
                        {:else if gameItems[item.id].category === 'researchScroll'}
                            <ItemResearchScroll {item} gameItem={gameItems[item.id]}/>
                        {:else}
                            <div class="missing">
                                Missing item {gameItems[item.id].category} : {item.id}s
                            </div>
                        {/if}
                    {/each}
                {/if}
                {#if storePack.giftType !== undefined}
                    <ItemAllianceGift {storePack}/>
                {/if}
            </div>
        </div>
    {:catch e}
        Error resolving promise
    {/await}
{/if}

<style lang="scss">
  @import '../styles/vars.scss';

  :global {
    .pack {
      &.goldPacks {
        .items {
          margin-top: 60px !important;
        }
      }

      &.resources {
        .items {
          display: none !important;
        }
      }
    }
  }

  .items {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 10px 5px 10px 5px;
    border-radius: 6px;
    margin-top: $store-pack-header-height + 35;
    width: 100%;

    .mask {
      /* scroll bar width, for use in mask calculations */
      --scrollbar-width: 4px;
      --scrollbar-color: rgba(0, 0, 0, 0.2);

      /* mask fade distance, for use in mask calculations */
      --mask-height: 20px;

      /* If content exceeds height of container, overflow! */
      overflow-y: auto;

      /* Our height limit */
      height: $store-pack-height - 320;

      /* Need to make sure container has bottom space,
otherwise content at the bottom is always faded out */
      padding-bottom: var(--mask-height);
      padding-top: 10px;

      /* Keep some space between content and scrollbar */
      padding-right: 10px;

      /* The CSS mask */

      /* The content mask is a linear gradient from top to bottom */
      --mask-image-content: linear-gradient(
                      to bottom,
                      transparent,
                      black var(--mask-height),
                      black calc(100% - var(--mask-height)),
                      transparent
      );

      scrollbar-width: thin; /* can also be normal, or none, to not render scrollbar */
      scrollbar-color: var(--scrollbar-color) transparent; /* foreground background */

      /* Here we scale the content gradient to the width of the container 
minus the scrollbar width. The height is the full container height */
      --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;

      /* The scrollbar mask is a black pixel */
      --mask-image-scrollbar: linear-gradient(black, black);

      /* The width of our black pixel is the width of the scrollbar.
The height is the full container height */
      --mask-size-scrollbar: var(--scrollbar-width) 100%;

      /* Apply the mask image and mask size variables */
      mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
      mask-size: var(--mask-size-content), var(--mask-size-scrollbar);

      /* Position the content gradient in the top left, and the 
scroll gradient in the top right */
      mask-position: 0 0, 100% 0;

      /* We don't repeat our mask images */
      mask-repeat: no-repeat, no-repeat;

      &::-webkit-scrollbar {
        width: var(--scrollbar-width);
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--scrollbar-color);
        border-radius: 9999px; /* always round */
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    }
  }

  .rewards {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 10px 5px 10px 5px;
    border-radius: 6px;
    margin-top: 15px;
    width: 100%;

    .mask {
      /* scroll bar width, for use in mask calculations */
      --scrollbar-width: 4px;
      --scrollbar-color: rgba(0, 0, 0, 0.2);

      /* mask fade distance, for use in mask calculations */
      --mask-height: 20px;

      /* If content exceeds height of container, overflow! */
      overflow-y: auto;

      /* Our height limit */
      height: 160px;

      /* Need to make sure container has bottom space,
otherwise content at the bottom is always faded out */
      padding-bottom: var(--mask-height);
      padding-top: 10px;

      /* Keep some space between content and scrollbar */
      padding-right: 10px;

      /* The CSS mask */

      /* The content mask is a linear gradient from top to bottom */
      --mask-image-content: linear-gradient(
                      to bottom,
                      transparent,
                      black var(--mask-height),
                      black calc(100% - var(--mask-height)),
                      transparent
      );

      scrollbar-width: thin; /* can also be normal, or none, to not render scrollbar */
      scrollbar-color: var(--scrollbar-color) transparent; /* foreground background */

      /* Here we scale the content gradient to the width of the container 
minus the scrollbar width. The height is the full container height */
      --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;

      /* The scrollbar mask is a black pixel */
      --mask-image-scrollbar: linear-gradient(black, black);

      /* The width of our black pixel is the width of the scrollbar.
The height is the full container height */
      --mask-size-scrollbar: var(--scrollbar-width) 100%;

      /* Apply the mask image and mask size variables */
      mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
      mask-size: var(--mask-size-content), var(--mask-size-scrollbar);

      /* Position the content gradient in the top left, and the 
scroll gradient in the top right */
      mask-position: 0 0, 100% 0;

      /* We don't repeat our mask images */
      mask-repeat: no-repeat, no-repeat;

      &::-webkit-scrollbar {
        width: var(--scrollbar-width);
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--scrollbar-color);
        border-radius: 9999px; /* always round */
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    }
  }
</style>